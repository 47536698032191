import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  ChainName,
} from "@types";
import BaseService from "./BaseService";
import { BTC_ICON, DEFAULT_TOKEN } from "src/utils/constants";
import { createActor } from "./candids";
import {
  idlFactory as CosmProxyFactory,
  _SERVICE as CosmProxyService,
} from "./candids/CosmProxy.did";
import {
  idlFactory as GenericProxyFactory,
  _SERVICE as GenericProxyService,
} from "./candids/GenericProxy.did";
import OsmosisRouteService from "./OsmosisRouteService";

const OSMOSIS_PROXY_CANISTER_ID = "ncfbq-kyaaa-aaaar-qah3a-cai";
const GENERIC_PROXY_CANISTER_ID = "c2bzs-5iaaa-aaaar-qakta-cai";

export default class BitcoinckBTCService extends BaseService {
  async getTokenList(): Promise<Token[]> {
    const token: Token = {
      id: "bitcoin",
      name: "BTC",
      symbol: "BTC",
      decimals: 8,
      icon: BTC_ICON,
      balance: 0n,
      token_id: DEFAULT_TOKEN[ChainID.BitcoinckBTC],
      fee: 0n,
      chain_id: ChainID.BitcoinckBTC,
    };

    return Promise.resolve([token]);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    return this.chain.token_list || [];
  }
  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Transfer",
        description: "Send your BTC",
      },
    ];
  }

  static async getckBTCAddress(
    targetAddr: string,
    targetChainId: ChainID,
  ): Promise<string> {
    if (targetChainId === ChainID.Osmosis) {
      const actor = createActor<CosmProxyService>(
        OSMOSIS_PROXY_CANISTER_ID,
        CosmProxyFactory,
      );

      const ckbtcAddrResult = await actor.get_btc_mint_address(targetAddr);
      if ("Err" in ckbtcAddrResult) {
        throw new Error(ckbtcAddrResult.Err);
      }
      return ckbtcAddrResult.Ok;
    } else {
      const actor = createActor<GenericProxyService>(
        GENERIC_PROXY_CANISTER_ID,
        GenericProxyFactory,
      );
      const ckbtcAddrResult =
        await actor.query_btc_mint_address_by_omnity_account({
          chain_id: targetChainId,
          account: targetAddr,
        });

      if ("Err" in ckbtcAddrResult) {
        throw new Error(Object.keys(ckbtcAddrResult.Err)[0]);
      }
      return ckbtcAddrResult.Ok;
    }
  }

  static async updateBalanceAfterFinalization(
    targetAddr: string,
    targetChainId: ChainID,
  ) {
    if (targetChainId === ChainID.Osmosis) {
      const actor = createActor<CosmProxyService>(
        OSMOSIS_PROXY_CANISTER_ID,
        CosmProxyFactory,
      );

      await actor.update_balance_after_finalization(targetAddr, [
        JSON.stringify({
          transmuter: OsmosisRouteService.BTC_DENOM,
        }),
      ]);
    } else {
      const actor = createActor<GenericProxyService>(
        GENERIC_PROXY_CANISTER_ID,
        GenericProxyFactory,
      );
      await actor.update_balance_after_finalization({
        chain_id: targetChainId,
        account: targetAddr,
      });
    }
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const { targetAddr, targetChainId, setStep, transfer, amount } = params;

    let tx_hash: string = "";
    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    if (targetChainId === ChainID.Osmosis) {
      const actor = createActor<CosmProxyService>(
        OSMOSIS_PROXY_CANISTER_ID,
        CosmProxyFactory,
      );

      const ckbtcAddrResult = await actor.get_btc_mint_address(targetAddr);
      if ("Err" in ckbtcAddrResult) {
        throw new Error(ckbtcAddrResult.Err);
      }
      const ckbtcAddr = ckbtcAddrResult.Ok;
      tx_hash = await transfer(ckbtcAddr, Number(amount.toString()));
      await actor.update_balance_after_finalization(targetAddr, [
        JSON.stringify({
          transmuter: OsmosisRouteService.BTC_DENOM,
        }),
      ]);
    } else {
      const actor = createActor<GenericProxyService>(
        GENERIC_PROXY_CANISTER_ID,
        GenericProxyFactory,
      );
      const ckbtcAddrResult =
        await actor.query_btc_mint_address_by_omnity_account({
          chain_id: targetChainId,
          account: targetAddr,
        });

      if ("Err" in ckbtcAddrResult) {
        throw new Error(Object.keys(ckbtcAddrResult.Err)[0]);
      }
      const ckbtcAddr = ckbtcAddrResult.Ok;
      tx_hash = await transfer(ckbtcAddr, Number(amount.toString()));
      await actor.update_balance_after_finalization({
        chain_id: targetChainId,
        account: targetAddr,
      });
    }

    setStep && setStep(1);

    return tx_hash;
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  generateTicket(
    ticket: Ticket,
  ): Promise<{ finalized: boolean; message?: string }> {
    throw new Error("Method not implemented.");
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  getBridgeFee(targetChainId: ChainID, token?: Token): Promise<BridgeFee> {
    return Promise.resolve({
      fee: 0n,
      symbol: "BTC",
      decimals: 8,
    });
  }
}
