import { HStack, Text, VStack } from "@chakra-ui/react";

type OminityCardProps = {
  width?: number;
  cardTitle: string;
  cardComponent: React.ReactNode;
  submissionComponent: React.ReactNode;
  headerProps?: {
    headerComponent: React.ReactNode;
  };
};
export const OminityCard = ({
  width,
  cardTitle,
  cardComponent,
  submissionComponent,
  headerProps,
}: OminityCardProps) => {
  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack width="100%" borderRadius="16px" gap={0} bgColor="bg.module">
        <HStack
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          paddingX={6}
          paddingY={4}
        >
          <Text textStyle="h5" color="secondary.main" fontWeight={600}>
            {cardTitle}
          </Text>
          {headerProps?.headerComponent}
        </HStack>

        <VStack
          w="full"
          bgColor="bg.main"
          padding={{ base: 4, md: 6 }}
          borderBottomLeftRadius="16px"
          borderBottomRightRadius="16px"
          gap="20px"
        >
          {cardComponent}
        </VStack>
      </VStack>

      {submissionComponent}
    </VStack>
  );
};
