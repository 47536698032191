import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as HubInterfaceFactory,
  _SERVICE,
} from "./candids/OmnityHub.did";
import { createActor } from "./candids";
import {
  AssetType,
  BridgeStep,
  Chain,
  ChainID,
  ChainState,
  ChainType,
  createActorFunctionType,
  Network,
} from "@types";
import { CHAIN_LIST, CKBTC_CHAIN_LIST } from "../utils/constants";
import ServiceFactory from "./Factory";
import { AccountIdentifier, LedgerCanister } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import { IDL } from "@dfinity/candid";
import { formatGenerateTicketError } from "src/utils/helper";

export default class HubService {
  network: Network;
  canisterId: string;
  private hubActor: ActorSubclass<_SERVICE>;
  private chains: Chain[] = [];

  constructor(network: Network) {
    this.network = network;
    this.canisterId = "7wupf-wiaaa-aaaar-qaeya-cai";
    this.hubActor = createActor<_SERVICE>(this.canisterId, HubInterfaceFactory);
  }

  async fetchChains(assetType: AssetType): Promise<Chain[]> {
    try {
      let _chains: Chain[] = [];
      if (assetType === AssetType.ckbtc) {
        _chains = CKBTC_CHAIN_LIST;
      } else {
        const res = await this.hubActor.get_chains([], [], 0n, 20n);
        if (!("Ok" in res)) {
          throw new Error("Failed to fetch chains");
        }
        _chains = res.Ok.filter((chain) => {
          const chain_id = chain.chain_id as ChainID;
          if (chain_id in CHAIN_LIST) {
            return true;
          }
          return false;
        }).map((chain) => {
          const chain_id = chain.chain_id as ChainID;
          return {
            chain_id,
            chain_name: CHAIN_LIST[chain_id].chainName,
            asset_type: assetType,
            canister_id: chain.canister_id,
            fee_token: chain.fee_token,
            chain_state:
              (Object.keys(chain.chain_state)[0] as ChainState) ??
              ChainState.Active,
            chain_type:
              (Object.keys(chain.chain_type)[0] as ChainType) ??
              ChainType.ExecutionChain,
            counterparties: (chain.counterparties[0] ?? []) as ChainID[],
            contract_address: chain.contract_address[0],
            evm_chain: CHAIN_LIST[chain_id].evmChain,
            service_type: CHAIN_LIST[chain_id].serviceType,
          };
        });
      }

      const chains = await Promise.all(
        _chains.map((chain) => ServiceFactory.getTokenList(chain)),
      ).then((res) => {
        return _chains.map((chain, index) => {
          return {
            ...chain,
            token_list: res[index],
          };
        });
      });

      return chains;
    } catch (error) {
      return [];
    }
  }

  getAddRunesSteps(): BridgeStep[] {
    return [
      {
        title: "Deposit",
        description: "Deposit fee to fee account",
      },
      {
        title: "Add",
        description: "Add Runes",
      },
    ];
  }

  getChains() {
    return this.chains;
  }

  async getSelfServiceFee() {
    return await this.hubActor.get_self_service_fee();
  }

  async onAddRunes({
    dest_chain,
    icon,
    rune_id,
    symbol,
    address,
    setStep,
    transfer,
    createActor,
  }: {
    dest_chain: ChainID;
    icon: string;
    rune_id: string;
    symbol: string;
    address: string;
    setStep: (step: number) => void;
    createActor: createActorFunctionType;
    transfer: (params: {
      to: string;
      amount: bigint;
    }) => Promise<number | bigint | undefined>;
  }) {
    try {
      const account = Principal.fromText(address);
      const actor: _SERVICE = await createActor<_SERVICE>(
        this.canisterId,
        HubInterfaceFactory,
        address,
      );
      const feeAccountArray = await this.hubActor.get_fee_account([account]);
      const lc = LedgerCanister.create();
      const feeAccount = Array.from(feeAccountArray)
        .map((i) => ("0" + i.toString(16)).slice(-2))
        .join("");
      const feeAccountBalance = await lc.accountBalance({
        accountIdentifier: feeAccount,
        certified: false,
      });
      const serviceFee = await this.hubActor.get_self_service_fee();
      if (!serviceFee) {
        throw new Error("Failed to get service fee");
      }

      if (feeAccountBalance < serviceFee.add_token_fee) {
        const depositAmount = serviceFee.add_token_fee - feeAccountBalance;
        const myIcpBalance = await lc.accountBalance({
          accountIdentifier: AccountIdentifier.fromPrincipal({
            principal: account,
          }),
          certified: false,
        });
        if (depositAmount >= myIcpBalance) {
          throw new Error("Insufficient balance");
        }
        // deposit
        await transfer({
          to: feeAccount,
          amount: depositAmount,
        });
      }
      setStep(1);

      const res = await actor.add_runes_token({
        dest_chain,
        icon,
        rune_id,
        symbol,
      });

      if ("Err" in res) {
        throw new Error(formatGenerateTicketError(res.Err));
      }
      setStep(2);
      return true;
    } catch (error) {
      throw error;
    }
  }

  getAddChainSteps(): BridgeStep[] {
    return [
      {
        title: "Deposit",
        description: "Deposit fee to fee account",
      },
      {
        title: "Add",
        description: "Add Chain",
      },
    ];
  }

  async onAddChainForRunes({
    dest_chain,
    token_id,
    address,
    setStep,
    transfer,
    createActor,
  }: {
    dest_chain: ChainID;
    token_id: string;
    address: string;
    setStep: (step: number) => void;
    createActor: createActorFunctionType;
    transfer: (params: {
      to: string;
      amount: bigint;
    }) => Promise<number | bigint | undefined>;
  }) {
    try {
      const account = Principal.fromText(address);
      const actor = await createActor<_SERVICE>(
        this.canisterId,
        HubInterfaceFactory,
        address,
      );
      const feeAccountArray = await this.hubActor.get_fee_account([account]);
      const lc = LedgerCanister.create();
      const feeAccount = Array.from(feeAccountArray)
        .map((i) => ("0" + i.toString(16)).slice(-2))
        .join("");
      const feeAccountBalance = await lc.accountBalance({
        accountIdentifier: feeAccount,
        certified: false,
      });
      const serviceFee = await this.hubActor.get_self_service_fee();
      if (!serviceFee) {
        throw new Error("Failed to get service fee");
      }
      if (feeAccountBalance < serviceFee.add_chain_fee) {
        const depositAmount = serviceFee.add_chain_fee - feeAccountBalance;
        const myIcpBalance = await lc.accountBalance({
          accountIdentifier: AccountIdentifier.fromPrincipal({
            principal: account,
          }),
          certified: false,
        });
        if (depositAmount >= myIcpBalance) {
          throw new Error("Insufficient balance");
        }
        // deposit
        await transfer({
          to: feeAccount,
          amount: depositAmount,
        });
      }
      setStep(1);

      const res = await actor.add_dest_chain_for_token({
        dest_chain,
        token_id,
      });
      if (!("Ok" in res)) {
        throw new Error("Failed to add runes");
      }
      setStep(2);
      return true;
    } catch (error) {
      throw error;
    }
  }
}
