import "./global.css";
import { Outlet } from "react-router-dom";
import Header from "./components/header";
import { Stack } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";

export default function Layout() {
  return (
    <Stack
      bg="bg.bgImage"
      color="white"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <Analytics />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Outlet />
      </div>
    </Stack>
  );
}
