import BaseService from "./BaseService";
import {
  BridgeFee,
  BridgeStep,
  ChainID,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  Token,
} from "../types";
import { Principal } from "@dfinity/principal";
import { IcrcLedgerCanister } from "@dfinity/ledger-icrc";
import {
  idlFactory as IcrcLedgerInterfaceFactory,
  _SERVICE as IcrcLedgerService,
} from "./candids/IcrcLedger.did";

export default class ICPService extends BaseService {
  async generateTicket(
    ticket: Ticket,
  ): Promise<{ finalized: boolean; message?: string }> {
    throw new Error("Method not implemented.");
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  getTokenList(): Promise<Token[]> {
    throw new Error("Method not implemented.");
  }

  fetchTokens(
    token_ids?: string[] | undefined,
    address?: string | undefined,
  ): Promise<Token[]> {
    throw new Error("Method not implemented.");
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    throw new Error("Method not implemented.");
  }

  onBridge(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async fetchICPTokenBalanceAndFee(
    canisterId: Principal,
    address?: string,
  ): Promise<{ balance: bigint; fee: bigint }> {
    const { balance, transactionFee } = IcrcLedgerCanister.create({
      canisterId,
    });
    const icrcFee = await transactionFee({ certified: false });
    if (!address) {
      return {
        balance: 0n,
        fee: icrcFee,
      };
    }
    const icrcBalance = await balance({
      owner: Principal.fromText(address),
      certified: false,
    });
    return { balance: icrcBalance, fee: icrcFee };
  }

  async fetchICPToken(
    canisterId: Principal,
    address?: string,
  ): Promise<Token | null> {
    try {
      const { metadata } = IcrcLedgerCanister.create({
        canisterId,
      });
      const data = await metadata({});

      let name: string = "";
      let symbol: string = "";
      let decimals: bigint = BigInt(0);
      let fee: bigint = BigInt(0);
      let logo: string = "";

      for (let i = 0; i < data.length; i++) {
        const ele = data[i];
        if (ele) {
          if (ele[0] === "icrc1:name") {
            const val = ele[1] as { Text: string };
            name = val.Text;
          } else if (ele[0] === "icrc1:symbol") {
            const val = ele[1] as { Text: string };
            symbol = val.Text;
          } else if (ele[0] === "icrc1:decimals") {
            const val = ele[1] as { Nat: bigint };
            decimals = val.Nat;
          } else if (ele[0] === "icrc1:fee") {
            const val = ele[1] as { Nat: bigint };
            fee = val.Nat;
          } else if (ele[0] === "icrc1:logo") {
            const val = ele[1] as { Text: string };
            logo = val.Text;
          }
        }
      }

      return {
        id: canisterId.toText(),
        decimals: Number(decimals),
        name,
        symbol,
        fee,
        icon: logo,
        balance: 0n,
        token_id: "",
        chain_id: ChainID.eICP,
      };
    } catch (error) {
      console.error("ICPService.fetchICPToken", error);
      return null;
    }
  }

  async onApprove({
    token,
    sourceAddr,
    amount,
    createActor,
  }: Pick<
    OnBridgeParams,
    "token" | "sourceAddr" | "amount" | "createActor"
  >): Promise<void> {
    if (!createActor) {
      throw new Error("createActor is required");
    }
    const spender = Principal.fromText(this.chain.canister_id);
    const account = Principal.fromText(sourceAddr);
    const { allowance, transactionFee } = IcrcLedgerCanister.create({
      canisterId: Principal.fromText(token.id),
    });
    // check allowance
    const txFee = await transactionFee({ certified: false });
    const approvingAmount = amount + txFee;
    const { allowance: allowanceAmount } = await allowance({
      spender: {
        owner: spender,
        subaccount: [],
      },
      account: {
        owner: account,
        subaccount: [],
      },
    });
    // approve
    const icrcLedger: IcrcLedgerService = await createActor<IcrcLedgerService>(
      token.id,
      IcrcLedgerInterfaceFactory,
      sourceAddr,
    );

    if (allowanceAmount < approvingAmount) {
      await icrcLedger.icrc2_approve({
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: approvingAmount,
        spender: {
          owner: spender,
          subaccount: [],
        },
        expires_at: [],
        expected_allowance: [],
      });
    }
  }

  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }

  static validateAddress(addr: string): boolean {
    try {
      Principal.fromText(addr);
      return true;
    } catch (error) {
      return false;
    }
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    return Promise.resolve({
      fee: BigInt(0),
      symbol: "eICP",
      decimals: 8,
    });
  }
}
