import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Layout from "./layout.tsx";
import { extendDefaultTheme } from "./theme/theme.ts";
import Runes from "./routes/runes.tsx";
import ICP from "./routes/icp.tsx";

// trigger deploy

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/icp" replace />,
      },
      {
        path: "runes/",
        element: <Runes />,
        children: [
          {
            path: ":tab",
            element: <Runes />,
          },
        ],
      },
      {
        path: "icp/",
        element: <ICP />,
        children: [
          {
            path: ":tab",
            element: <ICP />,
          },
        ],
      },
    ],
  },
]);

const extendedTheme = extendDefaultTheme();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={extendedTheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
);
