import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  Chain,
} from "@types";
import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as InterfaceFactory,
  _SERVICE,
} from "./candids/CosmRoute.did";
import { createActor } from "./candids/index";
import BaseService from "./BaseService";
import { BTC_ICON, DEFAULT_TOKEN } from "src/utils/constants";
import { StargateClient } from "@cosmjs/stargate";

const OSMOSIS_RPC = "https://rpc.osmosis.zone";

export default class OsmosisRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static BTC_CONFIRMATIONS_LIMIT = 4;
  static BTC_DENOM =
    "factory/osmo1z6r6qdknhgsc0zeracktgpcxf43j6sekq07nw8sxduc9lg0qjjlqfu25e3/alloyed/allBTC";

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(chain.canister_id, InterfaceFactory);
  }

  async getTokenList(): Promise<Token[]> {
    const token: Token = {
      id: OsmosisRouteService.BTC_DENOM,
      name: "BTC",
      symbol: "BTC",
      decimals: 8,
      icon: BTC_ICON,
      balance: 0n,
      token_id: DEFAULT_TOKEN[ChainID.BitcoinckBTC],
      fee: 0n,
      chain_id: ChainID.Osmosis,
    };

    return Promise.resolve([token]);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    try {
      const _tokens = this.chain.token_list || [];

      if (!address) {
        return _tokens;
      }

      const client = await StargateClient.connect(OSMOSIS_RPC);
      const tokens = await Promise.all(
        _tokens.map(async (t) => {
          let balance = 0n;
          try {
            const _balance = await client.getBalance(address, t.id);
            balance = BigInt(_balance.amount);
          } catch (error) {}
          return { ...t, balance };
        }),
      );

      return tokens;
    } catch (error) {
      return [];
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Transfer",
        description: "Send your BTC",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const { sourceAddr, targetAddr, amount, transfer, setStep } = params;
    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    const tx_hash = await transfer({
      fromAddr: sourceAddr,
      toAddr: targetAddr,
      amount,
    });
    const actor = createActor<_SERVICE>(
      this.chain.canister_id,
      InterfaceFactory,
    );
    const redeemResult = await actor.redeem(tx_hash);
    if ("Err" in redeemResult) {
      throw new Error(redeemResult.Err);
    }
    setStep && setStep(1);

    return tx_hash;
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  generateTicket(
    ticket: Ticket,
  ): Promise<{ finalized: boolean; message?: string }> {
    throw new Error("Method not implemented.");
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  getBridgeFee(targetChainId: ChainID, token?: Token): Promise<BridgeFee> {
    return Promise.resolve({
      fee: 0n,
      symbol: "OSM",
      decimals: 8,
    });
  }
}
