import {
  idlFactory as ICPRouteInterfaceFactory,
  _SERVICE,
} from "./candids/IcpRoute.did";
import { ActorSubclass } from "@dfinity/agent";
import {
  BridgeFee,
  BridgeStep,
  Chain,
  ChainID,
  createActorFunctionType,
  OnBridgeParams,
  OnBurnParams,
  ServiceType,
  Ticket,
  TicketStatus,
  TicketStatusResult,
  Token,
} from "../types";
import { createActor } from "./candids";
import ICPService from "./ICPService";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier, LedgerCanister } from "@dfinity/ledger-icp";
import { formatGenerateTicketError } from "../utils/helper";
import { IDL } from "@dfinity/candid";
import { getServiceTypeFromChainId } from "src/utils/chains";

export default class ICPRouteService extends ICPService {
  actor: ActorSubclass<_SERVICE>;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      ICPRouteInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    try {
      const tokenList = await this.actor.get_token_list();
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const [tokenLedger] = await this.actor.get_token_ledger(t.token_id);
            if (!tokenLedger) {
              throw new Error("Invalid token id");
            }
            const result = await this.fetchICPToken(tokenLedger, undefined);
            if (!result) {
              throw new Error("Invalid token id");
            }

            return {
              ...result,
              token_id: t.token_id,
              chain_id: this.chain.chain_id,
            };
          } catch (error) {
            return null;
          }
        }),
      );
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(
    token_ids?: string[] | undefined,
    address?: string | undefined,
  ): Promise<Token[]> {
    try {
      let tokenList = this.chain.token_list || [];
      if (Array.isArray(token_ids) && token_ids.length > 0) {
        tokenList = token_ids
          .map((id) => tokenList.find((r) => r.token_id === id))
          .filter((t) => !!t) as any;
      }

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const [tokenLedger] = await this.actor.get_token_ledger(t.token_id);
            if (!tokenLedger) {
              throw new Error("Invalid token id");
            }
            const { balance, fee } = await this.fetchICPTokenBalanceAndFee(
              tokenLedger,
              address,
            );

            return {
              ...t,
              balance,
              fee,
            };
          } catch (error) {
            return null;
          }
        }),
      );
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Approve",
        description: "Allow the bridge to transfer your tokens",
      },
      {
        title: "Deposit",
        description: "Deposit fee to fee account",
      },
      {
        title: "Bridge",
        description: "Generate Ticket",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      token,
      sourceAddr,
      targetAddr,
      targetChainId,
      setStep,
      amount,
      createActor,
      transfer,
    } = params;
    if (!createActor) {
      throw new Error("createActor is required");
    }
    const actor = await createActor<_SERVICE>(
      this.chain.canister_id,
      ICPRouteInterfaceFactory,
      sourceAddr,
    );

    try {
      await this.prepareForGenerateTicket({
        token,
        userAddr: sourceAddr,
        amount,
        targetChainId,
        setStep,
        transfer,
        createActor,
      });
    } catch (error) {}

    const service_type = getServiceTypeFromChainId(targetChainId);
    const ticketResult = await actor.generate_ticket({
      token_id: token.token_id,
      action:
        service_type === ServiceType.Customs
          ? { Redeem: null }
          : { Transfer: null },
      from_subaccount: [],
      target_chain_id: targetChainId,
      amount,
      receiver: targetAddr,
    });

    if ("Err" in ticketResult) {
      throw new Error(formatGenerateTicketError(ticketResult.Err));
    }
    setStep && setStep(3);

    return ticketResult.Ok.ticket_id;
  }

  async onBurn(params: OnBurnParams): Promise<string> {
    const { token, burnAddr, amount, targetChainId, transfer, createActor } =
      params;
    if (!createActor) {
      throw new Error("createActor is required");
    }
    const actor = await createActor<_SERVICE>(
      this.chain.canister_id,
      ICPRouteInterfaceFactory,
      burnAddr,
    );

    await this.prepareForGenerateTicket({
      token,
      userAddr: burnAddr,
      amount,
      targetChainId,
      transfer,
      createActor,
    });

    const ticketResult = await actor.generate_ticket({
      token_id: token.token_id,
      action: { Burn: null },
      from_subaccount: [],
      target_chain_id: targetChainId,
      amount,
      receiver: "",
    });
    if ("Err" in ticketResult) {
      throw new Error(formatGenerateTicketError(ticketResult.Err));
    }

    return ticketResult.Ok.ticket_id;
  }

  async generateTicket(
    ticket: Ticket,
  ): Promise<{ finalized: boolean; message?: string }> {
    throw new Error("Method not implemented.");
  }

  async prepareForGenerateTicket({
    token,
    userAddr,
    amount,
    setStep,
    targetChainId,
    transfer,
    createActor,
  }: {
    token: Token;
    userAddr: string;
    amount: bigint;
    targetChainId: ChainID;
    setStep?: (step: number) => void;
    transfer?: (params: {
      to: string;
      amount: bigint;
    }) => Promise<number | bigint | undefined>;
    createActor?: createActorFunctionType;
  }) {
    if (!transfer) {
      throw new Error("transfer is required");
    }
    if (amount !== 0n) {
      await this.onApprove({
        token,
        sourceAddr: userAddr,
        amount,
        createActor,
      });
      setStep && setStep(1);
    }

    const account = Principal.fromText(userAddr);
    const [redeemFee] = await this.actor.get_redeem_fee(targetChainId);
    if (redeemFee === undefined) {
      throw new Error("Redeem fee not set");
    }
    const feeAccountArray = await this.actor.get_fee_account([account]);
    const lc = LedgerCanister.create();

    const feeAccount = Array.from(feeAccountArray)
      .map((i) => ("0" + i.toString(16)).slice(-2))
      .join("");
    const redeemAccountBalance = await lc.accountBalance({
      accountIdentifier: feeAccount,
      certified: false,
    });

    // deposit redeem fee
    if (redeemAccountBalance < redeemFee) {
      const depositAmount = redeemFee - redeemAccountBalance;
      // check balance
      const myIcpBalance = await lc.accountBalance({
        accountIdentifier: AccountIdentifier.fromPrincipal({
          principal: account,
        }),
        certified: false,
      });
      if (depositAmount >= myIcpBalance) {
        throw new Error("Insufficient balance");
      }
      // deposit
      await transfer({
        to: feeAccount,
        amount: depositAmount,
      });
    }
    setStep && setStep(2);
  }

  async onMint(params: OnBridgeParams): Promise<string> {
    const {
      token,
      sourceAddr,
      targetAddr,
      targetChainId,
      amount,
      transfer,
      createActor,
    } = params;
    if (!createActor) {
      throw new Error("createActor is required");
    }
    const actor = await createActor<_SERVICE>(
      this.chain.canister_id,
      ICPRouteInterfaceFactory,
      sourceAddr,
    );

    await this.prepareForGenerateTicket({
      token,
      userAddr: sourceAddr,
      amount: 0n,
      targetChainId,
      transfer,
      createActor,
    });

    const ticketResult = await actor.generate_ticket({
      token_id: token.token_id,
      action: { Mint: null },
      from_subaccount: [],
      target_chain_id: targetChainId,
      amount,
      receiver: targetAddr,
    });

    if ("Err" in ticketResult) {
      throw new Error(formatGenerateTicketError(ticketResult.Err));
    }

    return ticketResult.Ok.ticket_id;
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.mint_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    const statusValue = Object.values(res)[0];
    let tx_hash;
    if (status === TicketStatus.Finalized) {
      tx_hash = statusValue?.block_index.toString();
    }
    return {
      status,
      tx_hash,
    };
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const [redeemFee] = await this.actor.get_redeem_fee(targetChainId);
    let fee = 0n;
    if (redeemFee !== undefined) {
      fee = redeemFee;
    }

    return {
      fee,
      symbol: "ICP",
      decimals: 8,
    };
  }
}
