import RevertButton from "@components/common/RevertButton";
import CurrentWallet from "@components/common/CurrentWallet";
import TokenSelector from "@components/TokenSelector";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTransferContext } from "../context/TransferContext";
import { useEffect, useState } from "react";
import { ChainName, Token } from "../types";
import useTransferService from "@hooks/useTransferService";
import ChainButton from "@components/common/ChainButton";
import TokenBalance from "@components/TokenBalance";
import { ChainSubmitButton } from "@components/SubmitButton/BaseSubmit";
import TokenOnL2 from "@components/TokenOnL2";
import { OminityCard } from "./OminityCard";
import TicketHistory from "@components/TicketHistory";
import { SubmitTransfer } from "@components/SubmitButton/SubmitButton";

export default function BitfinityTransfer({ width }: { width?: number }) {
  return (
    <OminityCard
      width={width}
      cardTitle="Transfer"
      cardComponent={<TransferComponent />}
      submissionComponent={<SubmitTransfer />}
      headerProps={{
        headerComponent: <TicketHistory isBitfinity />,
      }}
    />
  );
}

const TransferComponent = () => {
  const {
    sourceChain,
    targetChain,
    targetAddr,
    useConnectWalletForReceiver,
    onToggleConnectWalletForReceiver,
    onTargetAddrChange,
    token,
    onTokenChange,
    amount,
    onAmountChange,
    reversible,
    onRevert,
  } = useTransferContext();

  const [targetToken, setTargetToken] = useState<Token>();
  const [loadingTargetToken, setLoadingTargetToken] = useState(false);
  const { targetService } = useTransferService();

  useEffect(() => {
    if (targetService && token) {
      setLoadingTargetToken(true);
      targetService.fetchTokens([token.token_id], undefined).then((tokens) => {
        setLoadingTargetToken(false);
        setTargetToken(tokens[0]);
      });
    }
  }, [targetService, token?.token_id, targetToken?.token_id]);
  return (
    <>
      <Stack
        flexDir={{ base: "column", md: "row" }}
        alignItems="center"
        gap={4}
        w="100%"
      >
        <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between" gap={1.5}>
            <Text fontWeight={600} color="text.secondary">
              From
            </Text>
            <CurrentWallet chain={sourceChain} isSource isBitfinity />
          </HStack>
          <ChainButton chainName={sourceChain} isSource isBitfinity />
        </VStack>

        <RevertButton reversible={reversible} onRevert={onRevert} />

        <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text textStyle="h6" fontWeight={600} color="text.secondary">
              To
            </Text>
            {useConnectWalletForReceiver && targetAddr && (
              <CurrentWallet chain={targetChain} isSource={false} />
            )}
          </HStack>
          <ChainButton chainName={targetChain} isBitfinity />
        </VStack>
      </Stack>

      <VStack w="100%" alignItems="flex-start" gap={2}>
        <HStack width="full" justifyContent="space-between">
          <Text textStyle="h6" fontWeight={600} color="text.secondary">
            Amount
          </Text>
          <HStack w="100%" alignItems="center" justifyContent="flex-end">
            <HStack fontSize={{ base: 14, md: 16 }}>
              <Text textStyle="h6" color="text.secondary" fontWeight={600}>
                Balance:
              </Text>
              <TokenBalance />
            </HStack>
          </HStack>
        </HStack>
        <VStack
          w="100%"
          bgColor="bg.darkMain"
          borderRadius="8px"
          p={4}
          sx={{ "&:hover": { bg: "bg.darkHover" } }}
        >
          <HStack w="100%">
            <Input
              placeholder="0"
              color="text.secondary"
              flex={1}
              value={amount}
              fontSize={{
                base: 36 * (amount.length > 6 ? 0.8 : 1),
                md: 60 * (amount.length > 6 ? 0.8 : 1),
              }}
              py={`${amount.length > 6 ? 6 : 0}px`}
              lineHeight={1}
              px={0}
              fontWeight={600}
              borderWidth={0}
              height="100%"
              outline="none"
              _focus={{
                borderWidth: 0,
                outline: "none",
                boxShadow: "none",
              }}
              onChange={(e) => {
                const newValue = e.target.value;
                // fraction not longer than decimals
                let [_, fraction] = newValue.split(".");
                if (fraction && fraction.length > (token?.decimals ?? 0)) {
                  return;
                }
                if (/^\d+(\.)?(\d+)?$/.test(newValue) || newValue === "") {
                  onAmountChange(e.target.value);
                }
              }}
            />
            <TokenSelector token={token} setToken={onTokenChange} />
          </HStack>
        </VStack>
      </VStack>

      <VStack w="100%" alignItems="flex-start" gap={2}>
        <HStack w="100%" gap={2} justifyContent="space-between">
          <Text textStyle="h6" fontWeight={600} color="text.secondary">
            Receiver
          </Text>
          <HStack cursor="pointer">
            <FormControl display="flex" alignItems="center">
              <FormLabel
                htmlFor="connected-target-addr"
                mb="0"
                cursor="pointer"
                textStyle="h6"
                fontWeight={500}
                color="text.secondary"
              >
                Use Connected Wallet
              </FormLabel>
              <Switch
                id="connected-target-addr"
                isChecked={useConnectWalletForReceiver}
                onChange={onToggleConnectWalletForReceiver}
                sx={{
                  "& .chakra-switch__track[data-checked]": {
                    bg: "primary.main",
                  },
                }}
              />
            </FormControl>
          </HStack>
        </HStack>
        <HStack w="100%" borderRadius={4}>
          {((useConnectWalletForReceiver && targetAddr) ||
            !useConnectWalletForReceiver) && (
            <Input
              color="primary.main"
              bg="bg.module"
              padding={3}
              lineHeight={1}
              borderWidth={0}
              fontFamily="monospace"
              height="100%"
              outline="none"
              placeholder={
                targetChain === ChainName.ICP
                  ? "ICP Principal ID"
                  : `${targetChain} Address`
              }
              value={targetAddr}
              onChange={(e) =>
                !useConnectWalletForReceiver &&
                onTargetAddrChange(e.target.value)
              }
              _focus={{
                borderWidth: 0,
                outline: "none",
                boxShadow: "none",
              }}
            />
          )}
          {useConnectWalletForReceiver && !targetAddr && targetChain && (
            <ChainSubmitButton chainName={targetChain} forSubmit={false} />
          )}
        </HStack>
      </VStack>

      {token && (
        <TokenOnL2
          chainName={targetChain}
          token={targetToken}
          isLoading={loadingTargetToken}
          service={targetService}
          isBitfinity
        />
      )}
    </>
  );
};
